<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup class="card-sdw">
            <CCard 
                color="dark"
                text-color="white"
                class="p-4 p-1 text-center card-checkin"
            >

              <CCardBody>
                <CForm>
                  <!--<div v-on:click="seen = !seen">
                      <p>click app</p>
                  </div>
                        
                  <div v-if="seen">
                      <p>hide me </p>
                  </div>-->
                  <!-- v-on:click="seen = !seen" -->
                  <div v-if="eventData === 'Not found'" class="pt-7">
                    <div class="pt-7">
                      <h6>ไม่พบรายชื่อในการสอบ</h6>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!eventDetail" class="pt-7">
                      <div v-if="!seen" class="pt-5 pb-4">
                        <CButton color="outline-light" size="lg" class="px-5 py-3" @click="policyModal = true">
                          <CIcon :height="100" name="cil-task"/>&nbsp;Check-In
                        </CButton>
                        <div class="pt-2">
                          <h6>กระบวนวิชา {{eventName}}</h6>
                        </div>
                      </div>
                      <div v-if="seen">
                        <div class="text-fade success-checkmark">
                          <div class="check-icon">
                            <span class="icon-line line-tip"></span>
                            <span class="icon-line line-long"></span>
                            <div class="icon-circle"></div>
                            <div class="icon-fix"></div>
                          </div>
                        </div>
                        <h2>Check-in success!</h2>
                        <h6>รายละเอียดจะแสดงในอีก {{ countDown }} วินาที...</h6>
                      </div>
                    </div>
                    <div v-if="eventDetail" class="pt-0 text-fade">
                      <h5><b>ลิงค์ที่เกี่ยวข้องการสอบ</b></h5>
                      <div class="pt-1">
                        <CButton color="zoomblue" size="lg" class="px-5 py-3" @click="newtab(`https://cmu-th.zoom.us/j/${eventData.zoomMeeting.meetingid}`)">
                          <CIcon :height="100" name="cil-external-link"/>&nbsp;ZOOM Meeting
                        </CButton>
                      </div>
                      <div class="pt-2">
                        <CButton color="pastelpurple2" size="lg" class="px-5 py-3" @click="newtab(eventData.examLink)">
                          <CIcon :height="100" name="cil-external-link"/>&nbsp;CMU Exam Link
                        </CButton>
                      </div>
                      <div v-if="!checkOut" class="pt-2 pb-4">
                        <CButton color="danger" size="lg" class="px-2 py-2" @click="afterClickCheckout()">
                          <CIcon :height="100" name="cil-arrow-left"/>&nbsp;Check-out
                        </CButton>
                      </div>
                      <div v-if="checkOut" class="pt-3 pb-4 text-green" >
                          <h6><b>คุณ Check-out เรียบร้อยแล้ว</b></h6>
                      </div>
                      <h5><b>ข้อมูลการติดต่อกรณีฉุกเฉิน</b></h5>
                      <div class="pt-2">
                        <CRow>
                          <CCol sm="4">
                            <h6><b>Event:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{eventData.eventName}}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>Email:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{eventData.contact.email}}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>เบอร์ติดต่อ:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{eventData.contact.phone}}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>อื่นๆ:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{eventData.contact.other}}</h6>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                  <!-- <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                  <div class="pt-3 d-lg-none">
                    <CButton color="outline-danger" size="sm" class="" @click="$router.push({path: '/signout'})">
                      log out
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              class="text-center py-7 d-md-down-none card-checkin"
              body-wrapper
            >
              <CCardBody>
                <h4>คุณเข้าสู่ระบบในชื่อ</h4><br>
                <div class="c-avatar c-avatar-xl bg-secondary">
                    <CIcon :height="42" name="cil-user"/>
                </div>
                <div class="pt-3">
                    <h3 class="animated pulse"><b>{{displayName}}</b></h3>
                    <!-- <p>5906106xx</p> -->
                </div>
                <div class="pt-3">
                    <CButton color="outline-danger" size="sm" class="" @click="$router.push({path: '/signout'})">
                      log out
                    </CButton>
                </div>
                <!--<CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>-->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <!-- Modal Policy Component -->
    <CModal
      title="ข้อกำหนดและเงื่อนไขในการใช้บริการ"
      :show.sync="policyModal"
    >
      ทีมงานกำลังพัฒนาระบบการสอบมีประสิทธิภาพมากยิ่งขึ้น
    ทางทีมงานจึงขอนุญาตเก็บข้อมูลการใช้งานของท่าน
    รวมถึงยอมรับข้อผิดพลาดของระบบที่เกิดจากการใช้งาน
    โปรดอนุญาติหากท่านยินยอมในพัฒนาการใช้บริการ
    เราจะทำอย่างดีที่สุดเพื่อรักษาข้อมูลส่วนตัวของท่าน
    
    <template #footer>
      <CButton @click="afterAcceptPolicy()" color="success">Accept</CButton>
      <CButton @click="policyModal = false" color="secondary">Close</CButton>
    </template>
    </CModal>
    <CModal
      title="Check-out Confirm?"
      :show.sync="confirmModal"
    >
    คุณต้องการยืนยันที่จะCheck-out
    <template #footer>
      <CButton @click="userCheckOut(userEmail)" color="danger">Ok</CButton>
      <CButton @click="confirmModal = false" color="secondary">Cancel</CButton>
    </template>
    </CModal>
    <!-- Modal Not fount Event Component -->
    <CModal
      title="ไม่พบรายชื่อของท่าน"
      :show.sync="notFoundModal"
    >
      ไม่พบรายชื่อในการสอบโปรดตรวจสอบลิงค์ Check-In หากแน่ใจว่าลิงค์ถูกต้องโปรดติดต่ออาจารย์ผู้สอนในรายวิชาของท่าน
    
    <template #footer>
      <!-- <CButton @click="afterAcceptPolicy()" color="success">Accept</CButton> -->
      <CButton @click="notFoundModal = false" color="secondary">Close</CButton>
    </template>
    </CModal>
  </div>
</template>

<script>
import {HTTP} from "@/axios.js";
export default {
  name: 'CheckIn',
  data(){
    return {
      // userEmail:"nuttapong_pongkam@cmu.ac.th",
      userEmail:localStorage.getItem('email'),
      userData:[],
      displayName: localStorage.getItem('name'),
      seen: false,
      confirmModal: false,
      policyModal: true,
      notFoundModal: false,
      acceptPolicy:false,
      eventDetail:false,
      checkOut: false,
      countDown : 3,
      eventName: "",
      eventData:[],
      eventId:"",
    }
  },
  created() {
    this.$router.push({path: '/pages/nopermission'})
    document.title = "Student Check-in"
    console.log(this.userEmail,this.displayName)
    console.log("query:",this.$route.query)
    if(this.$route.query.eventid){
      this.eventId = this.$route.query.eventid
      console.log("seteventid",this.eventId)
    }
    this.getEventByUserEmail(this.userEmail)
  },
  mounted() {
    // this.checkUserCheckInStatus(this.userEmail)
  },
  methods: {
    async afterClickCheckout() {
      console.log("checkout")
      // let res = await this.checkUserCheckInStatus(this.userEmail)
      // console.log("resaftercheckout:",res)
      let allFinishedExam = true
      for(let quiz of this.eventData.srcExam.mod_exam_list){
        console.log("quiz",quiz)
        let checkInStatus = await this.checkUserCheckInStatus(quiz, this.userEmail)
        console.log("🚀 ~ afterClickCheckout ~ checkInStatus", checkInStatus)
        if(checkInStatus.state_exam_status !== "finished" && checkInStatus.state_exam_status !== "submitted"){
          allFinishedExam = false
          console.log("Not finished All")
        }
      }
      if(!allFinishedExam){
        this.$toast.warning('คุณยังไม่ได้ส่งข้อสอบ',{
          position: 'top' ,
          duration: 3000
        })
      }else{
        this.confirmModal = true
        // this.userCheckOut(this.userEmail)
      }
    },
    async afterAcceptPolicy() {
      console.log("Hi")
      let res = await this.userCheckIn(this.userEmail)
      if(res.state_checkin !== true){
        this.$toast.error('Check-in Fail please try again',{
          position: 'top' ,
          duration: 1000
        })
        setTimeout(() => this.$router.go(this.$router.currentRoute), 800);
        // this.$router.go(this.$router.currentRoute)
      }else{
        this.policyModal = false
        this.seen = true
        this.countDownTimer()
      }
      
    },
    countDownTimer() {
      if(this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      }else{
        this.showEventDetail()
      }
    },
    showEventDetail() {
      console.log("Detail")
      this.eventDetail = true
    },
    newtab(url){
      var win = window.open(url, '_blank');
      win.focus();
    },
    async getEventByUserEmail(email){
      let response = await HTTP.get(`api/event/ofuser?email=${email}`)
      .then(res => {
        if(res.data){
          console.log("getEventByUserEmail:",res.data)
          // eventId
          
          if(this.eventId !== ""){
            console.log("this.eventId:",this.eventId)
            for (let i = 0; i < res.data.length; i++) {
              console.log("data:",res.data[i])
              if(res.data[i] !== null){
                console.log("compare:",res.data[i]._id ," and ",this.eventId)
                console.log("compare result:",res.data[i]._id == this.eventId)
                if(res.data[i]._id == this.eventId){
                  this.eventName = res.data[i].eventName
                  return res.data[i]
                }
              }else{
                continue
              }
            }
            return 'Not found'
          }else{
            // this.eventName = res.data[0].eventName
            // return res.data[0]
            return 'Not found'
          }
          
        }
      })
      .catch(e => {
        console.log(e);
      });
      this.eventData = response
      console.log("🚀 ~ getEventByUserEmail ~ response", response)
      console.log("eventData",this.eventData)
      if(this.eventData !== 'Not found' && this.eventData !== undefined){
        console.log("this.eventData.srcExam.mod_exam_list:",this.eventData.srcExam.mod_exam_list)
        let allTrueCheckIn = true
        let allTrueCheckOut = true
        for(let quiz of this.eventData.srcExam.mod_exam_list){
          console.log("quiz",quiz)
          let checkInStatus = await this.checkUserCheckInStatus(quiz, this.userEmail)
          if(checkInStatus.state_checkin === false){
            allTrueCheckIn = false
          }
          if(checkInStatus.state_checkout === false){
            allTrueCheckOut = false
          }
        }
        if(this.eventData.srcExam){
          if(allTrueCheckIn){
            this.policyModal = false
            this.acceptPolicy = true
            this.eventDetail = true
          }
          if(allTrueCheckOut){
            this.checkOut = true
          }
        }
        console.log("test")
        // this.checkUserCheckInStatus(this.userEmail)
      }else{
        this.policyModal = false
        this.notFoundModal = true
      }
      
    },
    async checkUserCheckInStatus(quiz, email) {
      console.log("checkUserCheckIn",this.eventData,email)
      let response = await HTTP.get(`/api/event/check/status?uuid=${this.eventData.liveKeyUUID}&email=${email}&state_exam_mod=${quiz.mod}&state_exam_modid=${quiz.modid}`)
      .then(res => {
        console.log(res)
        if(res.data){
          console.log("checkUserCheckInStatus:",res.data)
          // if(res.data.state_checkin){
          //   this.policyModal = false
          //   this.acceptPolicy = true
          //   this.eventDetail = true
          // }
          // if(res.data.state_checkout){
          //   this.checkOut = true
          // }
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async userCheckIn(email) {
      let response = await HTTP.get(`/api/event/checkin?eventid=${this.eventData._id}&uuid=${this.eventData.liveKeyUUID}&email=${email}&status=true`)
      .then(res => {
        if(res.data){
          console.log(res.data)
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async userCheckOut(email) {
      console.log(email)
      let response = await HTTP.get(`/api/event/checkout?eventid=${this.eventData._id}&uuid=${this.eventData.liveKeyUUID}&email=${email}&status=true`)
      .then(res => {
        if(res.data){
          console.log(res.data)
          this.$router.go(this.$router.currentRoute)
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
    }
  }

}
</script>
